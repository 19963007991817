import { Controller } from "@hotwired/stimulus";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import axios from "axios";
import { camelCase } from "lodash-es";
import { tinymceInit } from "@/plugins/tinymce";

let feiLicences = [];
let insurance = null;
let textInputDelay = null;

export default class extends Controller {
  static targets = [
    "productInput",
    "feiLicence",
    "personYearlyInsurance",
    "personYearlyInsurancePackage2",
    "productForm",

    "orderItemForm"
  ];

  productFormTargetConnected() {
    tinymceInit({
      toolbar:
        "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | link removeformat"
    });
  }

  orderItemFormTargetConnected(form) {
    form.querySelectorAll("select").forEach(el => {
      window.initSelect2(el);
    });

    const triggerValidation = () => {
      // Prevent creating record if its still valid
      form.querySelector(`[name="order_item[refresh]"]`).value = true;

      const submit = form.querySelector("button[type=submit]");
      submit.disabled = true;
      LoadingSpinnerHandler.add(submit);

      const formData = new FormData(form);

      // Make inputs be disabled to prevent dumb changes.
      form.querySelectorAll("input,select").forEach(el => {
        el.disabled = true;
      });

      const token = document.getElementsByName("csrf-token");
      axios({
        url: form.action,
        method: "POST",
        headers: {
          "X-CSRF-Token": token[0].content,
          Accept: "text/vnd.turbo-stream.html"
        },
        data: formData
      })
        .then(({ data }) => {
          Turbo.session.receivedMessageFromStream(data);
        })
        .catch(error => {
          console.error(error);
        });
    };

    form
      .querySelectorAll("input[type=radio],input[type=checkbox]")
      .forEach(el => {
        el.addEventListener("click", triggerValidation);
      });

    form.querySelectorAll("input[type=text]").forEach(el => {
      el.addEventListener("input", () => {
        clearTimeout(textInputDelay);
        textInputDelay = setTimeout(() => triggerValidation(), 1000);
      });
    });

    form.querySelectorAll("select").forEach(el => {
      $(el).on("input", triggerValidation); // Only trough jQuery will select2 trigger that input
    });
  }

  insuranceInput(evt) {
    if (evt.target.value && evt.target.value != "on") {
      insurance = camelCase(evt.target.value);
    } else {
      insurance = null;
    }
  }

  feiLicenceInput(evt) {
    if (evt.target.value === "endurance") {
      const targetInput = evt.target.form.querySelector(
        ".order_item_fei_horse_trainer"
      );
      // If does not exist, then exit function
      if (!targetInput) return;
      if (evt.target.checked) {
        targetInput.classList.remove("d-none");
      } else {
        targetInput.classList.add("d-none");
        targetInput.value = "";
      }
    }

    if (evt.target.checked) {
      feiLicences.push(evt.target.value);
    } else {
      feiLicences = feiLicences.filter(x => x != evt.target.value);
    }
  }
}
