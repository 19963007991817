import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "invoiceForm",
    "bankOptions",
    "informTransaction",
    "bankingCountrySelect"
  ];

  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();

    const invoiceRequest = document.querySelector("#order_invoice_request");
    if (invoiceRequest?.checked == true) {
      this.showInvoiceForm();
    }

    const termsAgreed = document.querySelector("#order_terms_agreed");
    if (termsAgreed?.checked == true) {
      this.agreeTerms();
    }
  }

  showInvoiceForm() {
    this.invoiceFormTarget.classList.toggle("d-none");
  }

  agreeTerms() {
    this.bankOptionsTarget.classList.toggle("d-none");
  }

  informTransaction() {
    this.informTransactionTarget.classList.toggle("d-none");
  }

  expandOrder(evt) {
    const target = document.querySelector(evt.target.dataset.rtsTarget);
    evt.target.querySelector("i").classList.toggle("counter-rotate");
    target.classList.toggle("d-none");

    const row = evt.target.closest("tr");
    row.classList.toggle("table-active");
  }

  checkAllOrderUpdate(evt) {
    $("input.order-select:checkbox").prop(
      "checked",
      $(evt.target).prop("checked")
    );
  }

  openOrderItemEdit(evt) {
    evt.preventDefault();
    const href = evt.currentTarget.href;
    axios({
      url: href,
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data: response }) => {
      Turbo.session.receivedMessageFromStream(response);

      window.requestAnimationFrame(() => {
        window.initSelect2();
        window.initDatepicker();
      });
    });
  }

  openConfirmationDialog(evt) {
    evt.preventDefault();
    const href = evt.currentTarget.href;
    axios({
      url: href,
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data: response }) => {
      Turbo.session.receivedMessageFromStream(response);
    });
  }

  confirmPaymentSubmission(event) {
    // Stimulus doesn't support custom events, so have to do this way
    event.target.addEventListener(
      "form:submit-end",
      ({ detail }) => {
        if (detail.formSubmission.result.success === true) {
          document.querySelector("#modal").addEventListener(
            "hidden.bs.modal",
            () => {
              detail.formSubmission.formElement.remove();
            },
            { once: true }
          );
          let modal = new Modal($("#modal")[0]);
          modal.hide();
        }
      },
      { once: true }
    );
  }

  bankingCountrySelectTargetConnected(el) {
    const value = el.value;

    document.querySelectorAll(".country-bankings").forEach(el => {
      el.classList.add("d-none");
    });

    document
      .querySelector(`#countryBanking-${value}`)
      .classList.remove("d-none");
  }

  updateBankSelections(evt) {
    const value = evt.target.value;

    document.querySelectorAll(".country-bankings").forEach(el => {
      el.classList.add("d-none");
    });

    document
      .querySelector(`#countryBanking-${value}`)
      .classList.remove("d-none");
  }

  toggleInvoicePayment(evt) {
    evt.target.disabled = true;
    axios({
      url: evt.params.url,
      method: "PATCH",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },
      data: {
        value: evt.target.checked ? "1" : "0"
      }
    }).then(({ data: response }) => {
      evt.target.disabled = false;
      Turbo.session.receivedMessageFromStream(response);
    });
  }
}
