import { Controller } from "@hotwired/stimulus";
import axios from "axios";

let controller = null;
let initialFetchOfficial = true;
export default class extends Controller {
  static targets = [
    "issuer",
    "discipline",
    "qualificationType",
    "officialSelection",
    "qualificationOfficialRightsInput"
  ];

  connect() {
    initialFetchOfficial = true;
  }

  disconnect() {
    controller?.abort();
  }

  fetchOfficials({ currentTarget }) {
    controller?.abort();
    controller = new AbortController();

    const form = currentTarget.closest(".official-form");
    const officialInput = form.querySelector(
      "[data-qualifications-target='officialSelection']"
    );

    const issuer = Array.from(
      form.querySelectorAll("[data-qualifications-target='issuer'] input")
    ).find(x => x.checked)?.value;
    const discipline = Array.from(
      form.querySelectorAll("[data-qualifications-target='discipline'] input")
    ).find(x => x.checked)?.value;
    const qualificationType = Array.from(
      form.querySelectorAll(
        "[data-qualifications-target='qualificationType'] input"
      )
    ).find(x => x.checked)?.value;

    setQualificationOfficialRightsInput(
      this.qualificationOfficialRightsInputTarget,
      initialFetchOfficial
    );

    initialFetchOfficial = false;

    if (issuer && discipline && qualificationType) {
      axios({
        url:
          officialInput.dataset.url +
          `?issuer=${issuer}&discipline=${discipline}&qualification_type=${qualificationType}`,
        method: "GET",
        headers: {
          Accept: "application/json"
        },
        signal: controller.signal
      })
        .then(({ data }) => {
          $(officialInput).empty();
          data.results.forEach((option, index) => {
            const elOption = new Option(option.text, option.id);
            elOption.setAttribute("data-fei", option.is_fei);
            elOption.setAttribute("data-default-max", option.default_max);
            $(officialInput).append(elOption).trigger("change");
          });

          // First time initiating this method is just to fetch data only
          const officialId = document.querySelector(
            "[name='original_official_id']"
          )?.value;

          if (officialId) {
            $(officialInput).val(officialId).trigger("change");
          } else {
            if (data.results.length) {
              $(officialInput).trigger({
                type: "select2:select",
                params: {
                  data: data.results[0]
                }
              });
            } else {
              $(officialInput).val(null).trigger("change");
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  officialSelectionTargetConnected(element) {
    this.fetchOfficials({ currentTarget: element });
    $(element).on("select2:select", ({ params: { data } }) => {
      const form = element.closest(".official-form");
      const validTill = form.querySelector(
        "[data-qualifications-target='validTill']"
      );

      let isFei, defaultMax;
      if (data.element) {
        isFei = data.element.dataset.is_fei;
        defaultMax = data.element.dataset.default_max;
      } else {
        isFei = data.is_fei;
        defaultMax = data.default_max;
      }

      if (defaultMax) {
        let date = DayJS().add(defaultMax, "years");
        if (!isFei) date = date.endOf("year");
        $(validTill).datepicker("setDate", date.toDate());
      }
    });
  }
}

function setQualificationOfficialRightsInput(
  inputWrapper,
  doNotAlterInputs = false
) {
  console.log(doNotAlterInputs);
  const discipline = Array.from(
    document.querySelectorAll("[data-qualifications-target='discipline'] input")
  ).find(x => x.checked)?.value;
  const qualificationType = Array.from(
    document.querySelectorAll(
      "[data-qualifications-target='qualificationType'] input"
    )
  ).find(x => x.checked)?.value;

  const inputs = inputWrapper.querySelectorAll("input[type='checkbox']");

  if (discipline === "dressage" && qualificationType === "judge") {
    inputWrapper.classList.remove("d-none");
    inputs.forEach(x => {
      if (!doNotAlterInputs) {
        x.checked = false;
      }
      x.disabled = false;
    });
  } else if (discipline === "dressage" && qualificationType === "steward") {
    inputWrapper.classList.remove("d-none");
    inputs.forEach(x => {
      if (!doNotAlterInputs) {
        x.checked = false;
      }

      if (x.labels[0].textContent == "Para") {
        x.disabled = false;
      } else {
        x.disabled = true;
      }
    });
  } else {
    inputWrapper.classList.add("d-none");
    inputs.forEach(x => {
      if (!doNotAlterInputs) {
        x.checked = false;
      }
      x.disabled = true;
    });
  }
}
