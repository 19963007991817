// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/activestorage").start();
require("channels");

import "./plugins/hotwired";
// Emit events trough window object
import EventEmitter from "events";
window.emitter = new EventEmitter();
import * as bootstrap from "bootstrap";

import jquery from "jquery";
// Makes it usable in views
window.$ = jquery;
// Required for bootstrap v5. Some places still use with jquery like in bootstrap v3/v4
window.jQuery = jquery;
window.bootstrapModal = bootstrap.Modal;
import axios from "axios";

require("@nathanvda/cocoon");

import confirmationDialog from "./mixins/confirmationDialog";

//import "./themeMode.js";

import "./plugins/datepicker";
import "./plugins/dayjs";
import "./plugins/datepicker";
import "./plugins/select2";
import tinymce from "tinymce";

document.addEventListener("turbo:visit", () => {
  document.body.classList.add("cursor-loading");
});

document.addEventListener("turbo:before-cache", () => {
  removeVisibleTooltips();
  tinymce.remove(); // remove or else it will not init again
  const toasts = document.querySelector("#toasts");
  if (toasts.childElementCount) {
    toasts.childNodes.forEach(el => el.remove());
  }
});

document.addEventListener("turbo:load", () => {
  document.body.classList.remove("cursor-loading");
  setupJavascript();

  const token = document.getElementsByName("csrf-token");
  if (token && token[0]?.content) {
    axios.defaults.headers.common["X-CSRF-Token"] = token[0].content;
    axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
  }
});

document.addEventListener("turbo:submit-end", e => {
  // Reinit tooltips
  tinymce.remove(); // remove or else it will not init again
  removeVisibleTooltips();
  initTooltips();

  // Do not scroll if form is inside modal, triggered by shadow form or form with button_to class
  // Shadow form as in rails ujs/turbo confirm is used on <a> and clicking it creates new one
  if (
    !(
      e.target.hidden || // When its link but has data-turbo-method="post" which creates seperate form action
      e.target.closest("#modal") || // When its inside modal or confirmation variant for turbo
      e.target.closest("#modalTurbo") ||
      e.detail.formSubmission.formElement.style.display ==
        "none" /* Shadow form is hidden*/ ||
      e.detail.formSubmission.formElement.classList.contains("button_to")
    )
  ) {
    document.querySelector("main.container").scrollIntoView();
    window.scroll(0, -50);
  }

  // This custom event needs to be manually attached trough stimulus and not trough data-action because
  // stimulus doesn't support custom events
  e.detail.formSubmission.formElement.dispatchEvent(
    new CustomEvent("form:submit-end", { detail: e.detail })
  );

  // submit form wont have preserved reactivity. Re-init them again
  setupJavascript();
});

/**
 * Setups up modal, scroll up, datepicker, select2
 * with timeout as
 */
function setupJavascript() {
  setTimeout(() => {
    setUpModal();
    setupScrollUp();
    setSessionMessage();
    initDatepicker();
    initSelect2();
    initTooltips();

    const navTabs = document.querySelectorAll(".nav.nav-tabs");
    if (navTabs.length > 0) {
      navTabs.forEach(el => {
        if (
          el.querySelector(".nav-link.active") ||
          (window.location.hash &&
            document.querySelector(
              /* format: xxx-'name here to open tab'*/
              `a.nav-link[href="#${window.location.hash.split("-")[1]}"]`
            ))
        ) {
          /**
           * If there is a nav-tabs with.active class, skip or if hash exists and tab nav found,
           * skip. (Should be handled in its respective controller, e.g people and marking_sheets controller)
           */
          return;
        }

        const navLink = el.querySelector(".nav-link");
        if (navLink) navLink.click();
      });
    }

    document.querySelectorAll(`.dropdown-toggle`).forEach(el => {
      new bootstrap.Dropdown(el, {
        boundary: "window"
      });
    });

    document.querySelectorAll(`[data-confirm]`).forEach(el => {
      el.addEventListener(
        "click",
        evt => {
          evt.preventDefault();
          evt.stopPropagation();
          confirmationDialog(evt);
        },
        { capture: true },
        true
      );
    });
  }, 50);
}

function removeVisibleTooltips() {
  document
    .querySelectorAll(`.tooltip[role="tooltip"]`)
    .forEach(el => el.remove());
}

function setUpModal() {
  const modal = document.querySelector("#modal");
  const modalInstance = bootstrap.Modal.getOrCreateInstance(modal);
  function hiddenCallback() {
    const modalContent = modal.querySelector("#modalContent");
    if (!modalContent) return;
    Array.from(modalContent.children).forEach(el => el.remove());
  }
  modal.addEventListener("hidden.bs.modal", hiddenCallback);
  const domObserver = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      for (const node of mutation.addedNodes) {
        if (node.name === "modalClose") {
          // If found input with that value in name attribute, then close modal automatically
          modalInstance.hide();
        } else if (node.name === "modalOpen") {
          // Or if its this value, then automatically open
          modalInstance.show();
        }
      }
    }
  });
  const modalContent = modal.querySelector("#modalContent");
  if (!modalContent) {
    modal.removeEventListener(hiddenCallback);
    return;
  }
  // Observe modal's content for input with name="closeModal"
  domObserver.observe(modalContent, { childList: true });
}

function setupScrollUp() {
  // Element that when hidden from view makes "scroll up" button shown
  const scrollBtnTigger = document.querySelector("#scrollBtnObserver");
  scrollBtnTigger.classList.add("scroll-observer");

  const scrollbtn = document.querySelector(".scroll-up");
  new IntersectionObserver(function (entries) {
    if (entries[0].intersectionRatio <= 0) {
      // When "#scrollBtnObserver" is no longer "visible" in browser
      scrollbtn.classList.remove("d-none");
    } else {
      // When "#scrollBtnObserver" is "visible" in browser again
      scrollbtn.classList.add("d-none");
    }
  }).observe(scrollBtnTigger);

  scrollbtn.addEventListener("click", () => {
    window.scrollTo(0, 0);
  });
}

function initTooltips() {
  document.querySelectorAll(`[data-bs-toggle="tooltip"]`).forEach(el => {
    new bootstrap.Tooltip(el);
  });
}

function setSessionMessage() {
  const notice = sessionStorage.getItem("notice");
  const alert = sessionStorage.getItem("alert");
  if (notice) {
    sessionStorage.removeItem("notice");
    const noticeBox = document.querySelector("#noticeAlert");
    noticeBox.innerText = notice;
    noticeBox.classList.remove("d-none");
  }
  if (alert) {
    sessionStorage.removeItem("alert");
    const alertBox = document.querySelector("#alertAlert");
    alertBox.innerText = alert;
    alertBox.classList.remove("d-none");
  }
}
