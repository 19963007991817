export const LOADING_SPINNER =
  '<span class="spinner-border spinner-border-sm me-2 rts-bootstrap-loading" role="status" aria-hidden="true"></span>';

export const LoadingSpinnerHandler = {
  add: el => {
    const node = document
      .createRange()
      .createContextualFragment(LOADING_SPINNER);
    el.prepend(node);
    if (el.querySelector("i.fas"))
      el.querySelector("i.fas").classList.add("d-none");
  },
  remove: el => {
    el.querySelector(".rts-bootstrap-loading").remove();
    if (el.querySelector("i.fas"))
      el.querySelector("i.fas").classList.remove("d-none");
  }
};

export function removeLoading() {
  $(".rts-loading-state").each(function (i, el) {
    $(el).removeClass("disabled");
    $(el).removeClass("rts-loading-state");
    if ($(el).find("i.fa").length >= 1) {
      $(el).find("i.fa").removeClass("d-none");
    }
  });
  $(".rts-bootstrap-loading").each(function (i, el) {
    $(el).remove();
  });
}
export function disableButton(el) {
  $(el).addClass("disabled rts-loading-state");
}

export function addLoading(el) {
  $(el).on("click", function () {
    if (this.tagName == "INPUT") {
      if ($(this).parent().find(".rts-bootstrap-loading").length == 0) {
        $(this).parent().prepend(LOADING_SPINNER);
      }
    } else {
      if ($(this).find(".rts-bootstrap-loading").length == 0) {
        $(this).prepend(LOADING_SPINNER);
      }
    }
    if ($(this).find("i.fa").length >= 1) {
      $(this).find("i.fa").first().addClass("d-none");
    }

    disableButton(this);
    setTimeout(function () {
      $("#confirmation_dialog").on("hide.bs.modal", function (e) {
        removeLoading();
      });
    }, 750);
  });
}

export function initLoadingIndicators() {
  $("#modal").on("show.bs.modal", function () {
    removeLoading();
  });

  $(`
    a.btn[href^="/"],
    button.btn,
    input.btn[name="commit"][type="submit"],
    button.btn[value^="save"][type="submit"],
    input.btn[value^="save"][type="submit"],
    input.btn[data_toggle="modal"]
  `).each(function (index, el) {
    addLoading(el);
  });
}
