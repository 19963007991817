const error = `<tr><td colspan="99" class="table-danger">
    <strong>Tekkis tõrge!</strong>
  </td>
</tr>
`;
const loading = `<tr class="rtspage-loading">
  <td colspan="99">
    <i class="fas fa-spinner fa-spin fa-lg me-2"></i><strong>Laen andmeid..</strong>
  </td>
</tr>
`;

import axios from "axios";

export default class RatsanetTable {
  /**
   * @desc Custom reactive table with features: search, filters, pagination and turbo-stream support
   * @param config.contentBodySelector String. Query selector to parent/wrapper, where it hosts form (search, filters) and table itself
   * @param config.itemsPerPage Integer. Number how much items to display per page
   * @param config.eagerFetch Boolean. When true, automatically fetch results from server
   * @param config.observerTrigger String. Example: When the table is inside a tab and is hidden, set a querySelector string to the tab that gets .active class.
   *                               When MutationObserver catches the tab element has received .active class, trigger query and disconnect the now redundant observer
   */
  constructor(config) {
    this.contentBodySelector = config.contentBodySelector;

    this.searchInputInUse = false;

    this._pagesRange = [];
    this.timer = null;
    this.options = {
      currentPageIndex: 0,
      itemsPerPage: config.itemsPerPage || 15,
      foundTotal: 0
    };

    if (this.$form) {
      this.$form.addEventListener("change", evt => {
        clearTimeout(this.timer);
        if (evt.target.dataset.searchFilter) {
          this.timer = setTimeout(() => {
            this.options.currentPageIndex = 0;
            this._fetchResults();
          }, 200);
        } else {
          this.options.currentPageIndex = 0;
          this._fetchResults();
        }
      });

      if (this.$form.querySelector("[data-search-filter]")) {
        this.$form
          .querySelector("[data-search-filter]")
          .addEventListener("input", ({ target }) => {
            const val = target.value;
            if (val && typeof val === "string" && val.length >= 3) {
              this.searchInputInUse = true;
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.options.currentPageIndex = 0;
                this._fetchResults();
              }, 750);
            } else if (this.searchInputInUse) {
              clearTimeout(this.timer);
              this.searchInputInUse = false;
              this.options.currentPageIndex = 0;
              this._fetchResults();
            }
          });
      }

      if (this.$table.getAttribute("data-rts-init") != "true") {
        if (config.eagerFetch === true) {
          this._fetchResults();
          this.$table.setAttribute("data-rts-init", "true");
        } else if (config.observerTrigger) {
          const observer = new MutationObserver(mutationList => {
            mutationList.forEach(mutation => {
              if (this.$table.getAttribute("data-rts-init") === "true") return;
              switch (mutation.type) {
                case "attributes":
                  if (mutation.target.classList.contains("active")) {
                    this._fetchResults();
                    this.$table.setAttribute("data-rts-init", "true");
                    observer.disconnect();
                  }
                  break;
              }
            });
          });
          const targetNode = document.querySelector(config.observerTrigger);
          observer.observe(targetNode, {
            childList: false,
            attributes: true,
            // Omit (or set to false) to observe only changes to the parent node
            subtree: false
          });
        } else {
          console.error(
            "config.observerTrigger is missing! Add one or set eagerFetch to true"
          );
        }
      } else {
        if (this.$paginationContainer) {
          // Turbo caches the page without event listeners on navigation control. Need to re-attach listeners for to work again
          this.options.currentPageIndex = Number(
            this.$paginationContainer.getAttribute(
              "data-rts-current-page-index"
            )
          );
          this.options.foundTotal = Number(
            this.$paginationContainer.getAttribute("data-rts-found-total")
          );

          this.pagesRange = this.options.foundTotal; // Triggers re-render on navigation control
        }
      }
    }
  }

  get $contentBody() {
    return document.querySelector(this.contentBodySelector);
  }

  get $form() {
    return this.$contentBody.querySelector("form");
  }

  get $table() {
    return this.$contentBody.querySelector("table");
  }

  get $tableBody() {
    return this.$contentBody.querySelector("table tbody");
  }

  get $paginationContainer() {
    return this.$contentBody.querySelector(
      ".pagination.rts-pagination-container"
    );
  }

  get pagesRange() {
    return this._pagesRange;
  }

  set pagesRange(v) {
    if (!this.$paginationContainer) return;
    this._pagesRange = this._updatePageRange(v);
    // Set these attributes so when user comes back and turbo gives cached version allow to re-render pagination with listeners
    // to have it work again
    this.$paginationContainer.setAttribute(
      "data-rts-current-page-index",
      this.options.currentPageIndex
    );
    this.$paginationContainer.setAttribute(
      "data-rts-found-total",
      this.options.foundTotal
    );
    this._renderPagination(); // Refresh pagination elements
  }

  _updatePageRange(totalCount) {
    const range = [];
    const pages = Math.ceil(parseInt(totalCount) / this.options.itemsPerPage);
    for (let i = 1; pages >= i; i++) {
      range.push(i);
    }
    return range.flat();
  }

  _renderPagination() {
    if (!this.$paginationContainer) return;
    this.$paginationContainer.innerHTML = ""; // Clear out to re-render

    let renderList = [];
    const pages = this.pagesRange;
    if (pages.length == 0) {
      return; // Do nothing
    } else if (pages.length <= 5) {
      // If only 5 pages, return all
      renderList = pages;
    } else {
      const currentPageIndex = this.options.currentPageIndex;
      if (currentPageIndex + 2 >= pages.length - 1) {
        renderList = pages.slice(renderList.length - 4, renderList.length - 1);
      } else {
        let start = currentPageIndex - 2 <= 0 ? 0 : currentPageIndex - 2;
        let end =
          currentPageIndex + 3 >= pages.length - 1
            ? pages.length - 1
            : currentPageIndex + 3;
        renderList = pages.slice(start, end);
      }

      if (!renderList.includes(1)) {
        const leftSide = [1];
        if (!renderList.includes(2)) {
          leftSide.push("...");
        }
        renderList = [...leftSide, ...renderList];
      }

      if (!renderList.includes(pages[pages.length - 1])) {
        const rightSide = [pages[pages.length - 1]];
        if (!renderList.includes(pages[pages.length - 2])) {
          rightSide.unshift("...");
        }
        renderList = [...renderList, ...rightSide];
      }
    }

    renderList.forEach(pageNum => {
      if (pageNum === "...") {
        var el = document.createElement("li");
        el.className = "page-number disabled rts-pagination-dots";
        el.setAttribute("tabindex", "-1");

        const linkElement = document.createElement("div");
        linkElement.classList.add("page-link");
        linkElement.classList.add("disabled");
        linkElement.setAttribute("aria-disabled", "true");
        linkElement.innerText = pageNum;
        el.appendChild(linkElement);
        this.$paginationContainer.appendChild(el);
        return;
      }
      var el = document.createElement("li");
      el.className = `${
        pageNum - 1 == this.options.currentPageIndex ? "active" : ""
      } page-item`;
      el.setAttribute("data-page-index", pageNum - 1);

      const linkElement = document.createElement("a");
      linkElement.classList.add("page-link");
      linkElement.innerText = pageNum;

      el.appendChild(linkElement);
      el.addEventListener("click", this._changePage.bind(this));
      this.$paginationContainer.appendChild(el);
    });
  }

  _changePage({ currentTarget: target }) {
    this.options.currentPageIndex = Number(target.dataset.pageIndex);
    this.$contentBody.scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
    this._fetchResults();
  }

  _fetchResults() {
    if (!this.$tableBody) return;
    this.$tableBody.innerHTML = loading;
    const form = this.$form;

    const formData = new FormData(form);
    const params = {
      target: `${this.contentBodySelector} table tbody`,
      offset: this.options.currentPageIndex * this.options.itemsPerPage,
      limit: this.options.itemsPerPage
    };

    for (let [key, v] of formData.entries()) {
      if (v) {
        if (v == "on") v = "1";
        params[key] = v;
      }
    }

    const token = document.getElementsByName("csrf-token")[0]?.content;
    axios({
      url: form.action,
      params,
      method: "get",
      headers: {
        Accept: "text/vnd.turbo-stream.html;charset=UTF-8;",
        "X-CSRF-Token": token
      }
    })
      .then(({ data: response }) => {
        Turbo.session.receivedMessageFromStream(response);
        setTimeout(() => {
          const totalFound = this.$tableBody.querySelector(
            "[data-result-total]"
          ).dataset.resultTotal;
          this.options.foundTotal = Number(totalFound);
          this.pagesRange = Number(totalFound);
        }, 100);
      })
      .catch(e => {
        console.error(e);
        this.$tableBody.innerHTML = error;
      });
  }
}
