import { Controller } from "@hotwired/stimulus";
import {
  openMobileDialog,
  closeMobileDialog
} from "@mixins/mobileDialogToggler";
import { createPopper } from "@popperjs/core";
import { backgroundJobStarted } from "@channels/ratsanet_jobs_channel";

export default class extends Controller {
  static targets = [
    "loginFormPopperAnchor",
    "broadcastJobsManualTriggerWatcher"
  ];

  broadcastJobsManualTriggerWatcherTargetConnected(el) {
    const domObserver = new MutationObserver(() => {
      console.log("Well hi!");
      backgroundJobStarted();
    });

    domObserver.observe(el, {
      childList: true,
      subtree: false,
      attributes: false
    });
  }

  openMobileDialog({ currentTarget }) {
    let content;
    if (currentTarget.dataset.target) {
      content = document
        .querySelector(currentTarget.dataset.target)
        .content.cloneNode(true);
      const externalDialog = document.querySelector("#mobileDialog");
      externalDialog.prepend(content);
      openMobileDialog(externalDialog.querySelector(".mobile-andmed"));
      externalDialog.classList.remove("d-none");
    } else {
      openMobileDialog($(currentTarget).siblings(".mobile-andmed")[0]);
    }
  }

  closeMobileDialog(evt) {
    evt.preventDefault();
    // .mobile-andmed is the parent for the whole dialog content
    const dialog = evt.currentTarget.closest(".mobile-andmed");
    closeMobileDialog(dialog);
    if (dialog.parentNode.id == "mobileDialog") {
      dialog.parentNode.removeChild(dialog);
      dialog.classList.add("d-none");
    }
  }

  mobileDialogSubmit(evt) {
    evt.preventDefault();
    // This method is intended for mobile dialog that's used as filter selection
    // It closes the dialog and then submits form
    const {
      currentTarget,
      params: { target }
    } = evt;

    const dialog = currentTarget.closest(".mobile-andmed");
    closeMobileDialog(dialog);

    if (dialog.parentNode.id == "mobileDialog") {
      dialog.parentNode.removeChild(dialog);
      dialog.classList.add("d-none");
    }

    setTimeout(() => {
      const submit = document.querySelector(`${target} button[type=submit]`);
      document.querySelector(`${target}`).requestSubmit(submit);
    }, 5);
  }

  expand({ params }) {
    const target = document.querySelector(params.target);
    if (target) {
      target.classList.toggle("d-none");
    }
  }

  async openLoginForm() {
    if (document.querySelector("#loginFormOverlay")) return;
    const loginForm = document.querySelector("#modalLoginForm");

    const popperInstance = createPopper(
      this.loginFormPopperAnchorTarget,
      loginForm,
      {
        placement: "bottom-end",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [10, 20]
            }
          }
        ]
      }
    );

    const loginFormExit = () => {
      loginForm.classList.add("d-none");
      document.querySelector("#loginFormOverlay")?.remove();
      document.body.classList.remove("modal-login-form-overflow-x");
      popperInstance.destroy();
      loginForm
        .querySelector(".close")
        .removeEventListener("click", loginFormExit);
    };

    loginForm.querySelector(".close").addEventListener("click", loginFormExit);

    const overlay = document.createElement("div");
    overlay.id = "loginFormOverlay";
    overlay.classList.add("rts-overlay");

    overlay.addEventListener("click", loginFormExit);

    document.body.append(overlay);
    document.body.classList.add("modal-login-form-overflow-x");

    loginForm.classList.remove("d-none");
  }
}
