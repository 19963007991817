import consumer from "./consumer";
import BackgroundJobToast from "@mixins/backgroundJobToast";
import axios from "axios";

const { subscriptions } = consumer;

// Re-render the toasts after turboloads again
document.addEventListener("turbo:load", () => {
  backgroundJobStarted();

  const channel = subscriptions.subscriptions.find(
    x => x.channelName == "RatsanetJobsChannel"
  );
  if (channel) {
    Object.keys(channel.jobs).forEach(job => {
      channel.jobs[job].render();
    });
  }
});

function createSubscription(jobs) {
  consumer.subscriptions.create("RatsanetJobsChannel", {
    channelName: "RatsanetJobsChannel",
    jobs: {},
    connected() {
      const jobsListed = Object.keys(this.jobs) ?? [];
      jobs.forEach(job => {
        if (!jobsListed.includes(job.queue_name)) {
          this.jobs[job.queue_name] = new BackgroundJobToast(job, {
            removeJob: removeBackgroundJobToast,
            cancelJob: cancelBackgroundJob
          });
        }
      });
      //console.log("Hi you are connected!");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      //console.log("bye ;-;");
      // Called when the subscription has been terminated by the server
    },

    received(jobRespond) {
      if (
        typeof jobRespond === "string" &&
        jobRespond.includes("<turbo-stream")
      ) {
        Turbo.session.receivedMessageFromStream(jobRespond);
        window.requestAnimationFrame(() => {
          initSelect2();
          initDatepicker();
        });
        return;
      } else if (jobRespond.turbo_action_url_html_selector) {
        turboStreamUpdateUrl(jobRespond.turbo_action_url_html_selector);
        return;
      }

      const toastJob = this.jobs[jobRespond.queue_name];
      if (toastJob) {
        toastJob.job = Object.assign(toastJob.job, jobRespond);
        // Just re-render
        toastJob.render();
      } else {
        this.jobs[jobRespond.queue_name] = new BackgroundJobToast(jobRespond, {
          removeJob: removeBackgroundJobToast,
          cancelJob: cancelBackgroundJob
        });
      }
    }
  });
}

export function backgroundJobStarted() {
  const token = document.getElementsByName("csrf-token");
  if (!token || !token[0]?.content) return;

  axios({
    url: "/home/any_background_jobs",
    method: "GET",
    responseType: "json",
    headers: {
      "X-CSRF-Token": token[0].content,
      Accept: "application/json"
    }
  }).then(({ data }) => {
    if (!data) return;
    const channel = subscriptions.subscriptions.find(
      x => x.channelName == "RatsanetJobsChannel"
    );
    if (!channel) createSubscription(data);
    if (data.length > 0) {
      BackgroundJobToast.updateTotalJobs(data.length);
      BackgroundJobToast.toggleFailedJobIndicator(
        data.some(({ error }) => error)
      );
      BackgroundJobToast.toggleFailedJobIndicator(false);

      if (channel) {
        data.forEach(job => {
          channel.jobs[job.queue_name] = new BackgroundJobToast(job, {
            removeJob: removeBackgroundJobToast,
            cancelJob: cancelBackgroundJob
          });
        });
      }
    }
  });
}

function cancelBackgroundJob(toastJob) {
  axios({
    method: "DELETE",
    url: "/home/delete_background_job_queue?queue=" + toastJob.job.queue_name,
    maxRedirects: 0 // no redirecting
  })
    .then(({ data }) => {
      if (data.deleted === true) {
        removeBackgroundJobToast(toastJob);
      }
    })
    .catch(err => {
      console.error(err);
    });
}

function removeBackgroundJobToast(toastJob) {
  const channel = subscriptions.subscriptions.find(
    x => x.channelName == "RatsanetJobsChannel"
  );
  delete channel.jobs[toastJob.job.queue_name];
}

function turboStreamUpdateUrl(selector) {
  // This only exists because when partial html contains any sign of wardern/cancancan it will just fail
  const url = document.querySelector(selector)?.dataset?.updateParam;
  if (url) {
    const token = document.getElementsByName("csrf-token");
    axios({
      url: url,
      method: "GET",
      maxRedirects: 0, // no redirecting,
      headers: {
        "X-CSRF-Token": token[0].content,
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data: response }) => {
      Turbo.session.receivedMessageFromStream(response);
    });
  }
}
